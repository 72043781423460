var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", { staticClass: "detail" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损单号")]),
            _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.id))])
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损人")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.reportUserName))
            ])
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损人手机号")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.reportUserPhone))
            ])
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损商品（件）")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.damageNum))
            ])
          ])
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损时间")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.createdTime))
            ])
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损理由")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.damageReason))
            ])
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("报损原因")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.damageDetail))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "lossReportingPic_box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("商品图片")]),
        _c(
          "div",
          { staticStyle: { display: "inline-block", "margin-left": "43px" } },
          _vm._l(_vm.picUrls, function(item, index) {
            return _c("img", {
              key: index,
              staticClass: "productPicture",
              attrs: { src: item, alt: "" }
            })
          }),
          0
        )
      ]),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticStyle: { "margin-bottom": "10px", "padding-left": "22px" }
            },
            [_vm._v("报损商品信息")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.commodityList, height: "390" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "storeName", align: "center", label: "门店信息" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "doorNameStr",
                  align: "center",
                  label: "柜门信息"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "itemImg", align: "center", label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("img", {
                          staticStyle: { height: "56px" },
                          attrs: { src: scope.row.itemImg, alt: "" }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  align: "center",
                  label: "商品品类"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "itemsName", align: "center", label: "商品名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "itemsCode", align: "center", label: "商品编号" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "itemsSellPrice",
                  align: "center",
                  label: "商品售价（元）"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "itemsNum", align: "center", label: "报损数量" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "itemsDetail",
                  align: "center",
                  label: "商品详细描述"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticStyle: { "white-space": "nowrap" },
                            attrs: { trigger: "hover", placement: "top" }
                          },
                          [
                            _c("p", [_vm._v(_vm._s(scope.row.itemsDetail))]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden"
                                },
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(scope.row.itemsDetail)
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }