<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="detail">
            <div class="content-left">
                <div class="content-box">
                    <div class="title">报损单号</div>
                    <div class="content">{{id}}</div>
                </div>
                <div class="content-box">
                    <div class="title">报损人</div>
                    <div class="content">{{reportUserName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">报损人手机号</div>
                    <div class="content">{{reportUserPhone}}</div>
                </div>
                <div class="content-box">
                    <div class="title">报损商品（件）</div>
                    <div class="content">{{damageNum}}</div>
                </div>

            </div>
            <div class="content-right">
                <div class="content-box">
                    <div class="title">报损时间</div>
                    <div class="content">{{createdTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">报损理由</div>
                    <div class="content">{{damageReason}}</div>
                </div>
                <div class="content-box">
                    <div class="title">报损原因</div>
                    <div class="content">{{damageDetail}}</div>
                </div>
            </div>
        </div>
        <div class="lossReportingPic_box">
            <div class="title">商品图片</div>
            <div style="display:inline-block;margin-left:43px;">
                <img class="productPicture" v-for="(item,index) in picUrls" :key="index" :src="item" alt="">
            </div>
        </div>
        <div>
            <div style="margin-bottom: 10px;padding-left:22px;">报损商品信息</div>
            <el-table :data="commodityList" height="390" style="width: 100%">
                <el-table-column prop="storeName" align="center" label="门店信息">
                </el-table-column>
                <el-table-column prop="doorNameStr" align="center" label="柜门信息">
                </el-table-column>
                <el-table-column prop="itemImg" align="center" label="商品图片">
                    <template slot-scope="scope">
                        <img :src="scope.row.itemImg" style="height:56px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="categoryName" align="center" label="商品品类">
                </el-table-column>
                <el-table-column prop="itemsName" align="center" label="商品名称">
                </el-table-column>
                <el-table-column prop="itemsCode" align="center" label="商品编号">
                </el-table-column>
                <el-table-column prop="itemsSellPrice" align="center" label="商品售价（元）">
                </el-table-column>
                <el-table-column prop="itemsNum" align="center" label="报损数量">
                </el-table-column>
                <el-table-column prop="itemsDetail" align="center" label="商品详细描述">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top" style="white-space: nowrap;">
                            <p>{{scope.row.itemsDetail}}</p>
                            <div slot="reference" style="text-overflow: ellipsis;overflow: hidden;">
                                {{scope.row.itemsDetail}}</div>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                title: "报损详情",
                picUrls: [],
                id: "",
                reportUserName: "",
                reportUserPhone: "",
                damageNum: "",
                createdTime: "",
                damageReason: "",
                damageDetail: "",
                commodityList: [],
            };
        },
        created() {},
        activated() {
            console.log("detail activated!!");
            let dto = {
                pageNum: 1,
                pageSize: 1,
                id: this.$route.query.id,
            };
            this.post("mall-service/itemsDamageRecord/page/query", dto, {
                isUseResponse: true
            }).then(res => {
                let data = res.data.list[0];
                if(data.itemsDamageResps.length > 0) {
                    data.itemsDamageResps.forEach(item => {
                        item.itemsDetail = item.itemsDetail ? item.itemsDetail : '--';
                        item.itemImg = item.itemPicUrlList && item.itemPicUrlList.length > 0 ? item.itemPicUrlList[0] : '';
                    });
                }
                this.commodityList = data.itemsDamageResps;
                this.id = data.id;
                this.reportUserName = data.reportUserName ? data.reportUserName : '--';
                this.reportUserPhone = data.reportUserPhone ? data.reportUserPhone : '--';
                this.damageNum = data.damageNum ? data.damageNum : '--';
                this.createdTime = data.createdTime ? data.createdTime : '--';
                this.damageReason = data.damageReason ? data.damageReason : '--';
                this.damageDetail = data.damageDetail ? data.damageDetail : '--';
                this.picUrls = data.picUrls;
            });
        },
        mounted() {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    .detail {
        margin: auto;
        width: 1000px;

        .content-left,
        .content-right {
            display: inline-block;
            width: 500px;
        }

        .content-right {
            float: right;
        }

        .content-box {
            margin: 20px 0;
            font-size: 15px;
            display: flex;

            .title {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
            }

            .content {
                color: #666;
                display: inline-block;
                width: 260px;
                word-wrap: break-word;
            }

            .describeContent {
                display: inline-block;
                width: 750px;
                word-wrap: break-word;
            }


        }
    }

    .lossReportingPic_box {
        width: 740px;
        margin: 0 auto;

        .title {
            display: inline-block;
            float: left;
        }

        .productPicture {
            width: 190px;
            height: 130px;
            margin: 8px;
        }
    }
</style>